<template>
  <div class="yzy displayF flex-directionC">
    <div class="top">
      <div class="title">项目介绍：</div>
      <div class="content">
        易知源数据管理系统是帮助人力资源公司收集供应商送人、员工入职信息采集的一体化系统，主要采取NFC技术将员工到场、面试、入职等状态进行统计分享，深度融合小程序、公众号、PC端，支持批量导入导出、批量更新、存档备份、支持自定义字段设置及表头展示等。全程跟踪员工轨迹记录，精准记录员工在职生涯成长印记。
        </br>
        </br>四大平台保障五大功能实现，用工“数智化”，深度挖掘员工数据，全程跟踪员工状态，解决蓝领用工信息记录难。通过APP端、PC端、公众号端报送数据，务工人员到岗面试、入职信息一刷记录，避免人工打字信息记录出现失误和偏差。
      </div>
    </div>
    <div class="bottom displayF justify-contentC align-itemsC">
      <img src="../../assets/yzy2.png" alt="" class="image" />
      <img src="../../assets/yzy3.png" alt="" class="image" />
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  data() {
    return {};
  },
  components: {},
  methods: {},
  mounted() {},
  computed: {},
  watch: {},
};
</script>

<style lang='scss' scoped>
.yzy {
  width: 100%;
  height: 100%;
  background: url("../../assets/yzy1.png");
  background-size: 100% 100%;
  .top {
    padding: 3.125rem 43.75rem 0 4.125rem;
    .title {
      font-size: 1.25rem;
    }
    .content {
      padding-left: 5rem;
      color: #222222;
      font-size: 1.125rem;
      margin-top: 0.625rem;
    }
  }
  .bottom {
    flex: 1;
    height: 100%;
    padding: 0 4.125rem;
    .image {
      width: 31.875rem;
      height: 24.375rem;
      margin-right: 2.5rem;
    }
  }
}
</style>